import React from "react";
import Index from "../views/AboutusAwards/Index";
import Layout from "../components/Layout";
function AboutusAwards({location}) {
  return (
    <div>
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
}

export default AboutusAwards;
