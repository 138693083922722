import React from "react";
import Headerp from "./../../images/trophy.png";

function Header() {
  return (
    <div className="header-blue">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div className="text-white">
            <div className="mb-2">
              <span className="font-medium text-xs text-white">
                {" "}
                ABOUT US OUR AWARDS
              </span>
            </div>
            <h1 className=" font-medium  lg:text-6xl md:text-5xl text-4xl mb-4 leading-none">
              Our Awards: Industry acknowledgements of excellence
            </h1>
            <p className="md:text-2xl text-xl mb-5">
              "Vonage offers a unique combination of best-of-breed solutions
              unified communications, programmable communications and most
              recently, contact center, to create an all-encompassing and
              extensible communications portfolio."{" "}
            </p>
            <p>—Michael Brandenburg, Industry Analyst, Connected Work</p>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
