import React from "react";
import Speak from "../../components/Speak";
import Cards from "./Cards.js";
import Header from "./Header";
import data from "./../../data";
import ReinventingCommunications from "./ReinventingCommunications";
import WereWinning from "./WereWinning";

function Index({location}) {
  return (
    <div>
      <Header />
      <ReinventingCommunications />
      <WereWinning />
      <Cards card3={data.card3} />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
